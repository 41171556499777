import {useTranslation} from "@i18n";
import stl from "./UnbxdTextSearch.module.css";
import {useLocation, useSearchParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import Suggestions from "./Suggestions/Suggestions";
import useUnbxdTextSearch from "../../../../../../common/hooks/useUnbxdTextSearch";
import {CDN_LINK} from "@globalConfig";
import useHelpers from "@hooks/useHelpers";
import {useAuthActions} from "@/_MiniApps/auth/AuthContext";


const UnbxdTextSearch = ({setPage}) => {
    const {t} = useTranslation();
    const existingSearches =
        []
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef();
    const [searchParams] = useSearchParams();
    const searchTermParam = searchParams.get("text");
    const {navigateTo, createUrl} = useHelpers();
    const location = useLocation();
    const {
        searchTerm,
        searchTermSuggestions,
        handleSearchTermChange,
        handleTextSearch,
        setSearchTerm,
        setSearchTermSuggestions,
    } = useUnbxdTextSearch(setPage);
    const {getSearchHistoryFromLocalStorage,syncSearchHistoryOnLogin} = useAuthActions(); // Access the function

    useEffect(() => {
        if (!searchTermParam) {
            setSearchTerm("");
            return;
        }

        setSearchTerm(searchTermParam);
    }, [location.search]);

    return (
        <form className={stl.wrapper}>
            <img
                src={CDN_LINK + "/assets/images/text-search-icon.svg"}
                width={15}
                height={15}
                alt="search"
            />
            <input
                type="text"
                placeholder={t("ابحث عن الماركة او الموديل", {ns: "web_search"})}
                onChange={handleSearchTermChange}
                value={searchTerm}
                onFocus={() => {
                    setIsFocused(true);
                    syncSearchHistoryOnLogin()
                }}
                onBlur={() => setTimeout(() => setIsFocused(false), 200)}
                ref={inputRef}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        inputRef.current.blur();
                        setSearchTermSuggestions([]);
                        handleTextSearch(e);
                    }
                }}
                className="interActiveGray"
            />
            {searchTermParam && searchTerm ? (
                <img
                    src={CDN_LINK + "/assets/images/clear.svg"}
                    alt=""
                    className={"hasEvents"}
                    onClick={(e) => {
                        e.stopPropagation();
                        setSearchTerm("");
                        setSearchTermSuggestions([]);

                        navigateTo("/filters");
                    }}
                />
            ) : (
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        handleTextSearch(e);
                    }}
                >
                    <span>{t("بحث", {ns: "web_search"})}</span>
                </button>
            )}

            {isFocused &&
                <div
                    className="border border-[#f5f7fa] bg-white left-0 right-0 top-[102%] rounded max-h-[476px] absolute overflow-y-auto shadow-[0_0_10px_rgba(188,188,188,0.4)] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100"
                    style={{scrollbarWidth: "thin"}}>
                    {searchTermSuggestions.length ? (
                        <Suggestions
                            searchTerm={searchTerm}
                            suggestionsData={searchTermSuggestions}
                            // handleTextSearch={handleTextSearch}
                            handleTextSearch={(e, sug) => {
                                handleTextSearch(e, sug)

                            }}
                        />
                    ) : null}

                    {getSearchHistoryFromLocalStorage().length > 0 &&
                        <div className="p-[24px] px-4 py-6">
                            <strong
                                className="text-[#484848] text-sm font-bold leading-[24px] block">{t("البحوث الأخيرة", {ns: "web_search"})}</strong>
                            <ul>
                                {getSearchHistoryFromLocalStorage().map((item, index) => {
                                    return index < 5 && <li key={index} className="flex items-center gap-1 mt-3">
                                        <img src={CDN_LINK + "/assets/images/searchHistoryIcon.svg"} width="24px"
                                             height="24px" alt="search history"/>
                                        <a className="rounded-[32px] border border-[#E1E8F2] bg-[#F1F6FC] flex p-2 justify-center items-center text-[#0E5BC0] text-xs font-semibold leading-[16px]"
                                           href={createUrl("/filters?text=" + item.text+"&fromRecentSearch=1")}>{item.text}</a>
                                    </li>
                                })}
                            </ul>
                        </div>
                    }
                </div>
            }
        </form>
    );
};

export default UnbxdTextSearch;
